import { NavBar, Icon, Image, Search, Toast, DropdownMenu, DropdownItem, List, PullRefresh } from 'vant'

export default {
  name: 'tickets',
  components: {
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-pull-refresh':PullRefresh,
    'van-list':List,
    'van-image':Image,
  },
  data () {
    return {
      isLoading: false,
      list: [],
      loading: false,
      finished: false,
      lookState:true,//
      params:{
        content:'',
        pageIndex:1,
        pageSize:10
      }

    }
  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  mounted () {
    this.params.content=this.$route.query.content
    this.init()
  },
  methods: {
    textFunc(text){//
      let num = ''
      text.length>30?(num=text.slice(0,30)+'...'):(num=text)
      return num

    },

    init(i) {
      const me = this;
      me.$post({
        url:'/search',
        params:me.params
      }).then(rep=>{
        if(i==1){//消除下拉的没有更多的 页面的 暂短出现
          me.list=[];
        }
        if(!rep.data.data.length||rep.data.data.length<me.params.pageSize){
          this.finished = true;
        }
        me.list.push(...rep.data.data)
        this.loading = false;
      })
    },
    routerPush(obj){
      console.log(obj);
      switch (obj.bizType) {
        case 10 :
          this.$router.push(`/foodrank/?uniqueId=${obj.bizId}`)
            break;
        case 5 :
          this.$router.push(`/guideDetails/?bizId=${obj.bizId}`)
          break;
        case 3 :
          this.$router.push(`/recreDetails/?seller=${obj.bizId}`)
          break;
        case 4 :
          this.$router.push(`/mallDetails/?commodityId=${obj.bizId}`)
          break;
        case 6:
          this.$router.push('/liveDetail/' + obj.bizId);
          break;
        case 1:
          // window.android.openPhotoTour(obj.bizId)
          // window.android.openVideoTour(obj.bizId)
          // if(obj.baseType){
          //
          // }else{
          //   window.android.openPhotoTour(obj.bizId)
          // }

          break;
      }



    },
    onClickLeft () {
      this.$router.go(-1)
    },
    onLoad () {
      const me = this;
      me.params.pageIndex++;
      me.init()
    },
    tiketRouter () {
      this.$router.push('/tiketDetails')
    },
    onRefresh () {
      Toast('刷新成功');
      this.isLoading = false;
      this.params.pageIndex=1;

      this.init(1)

    }
  }
}
